.fileContainer [type=file] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    box-shadow: 10px 10px 8px #888888;
}

.textstyle {
    font-weight: bolder;
    color: #b6b6b6;
    font-style: oblique;
    font-family: Arial, Helvetica, sans-serif;
    font-variant: small-caps;
    text-transform: uppercase;
    text-decoration: blink;
    vertical-align: baseline;
    line-height: 1cm;
    text-indent: inherit;
    vertical-align: text-bottom;
}