.ant-slider-track {
    display: none !important
}

.no-track {
    margin-top: 0px !important
}

.cropper-drag-box {
    background-color: white;
    border: 1px dashed #000000;
    border-radius: 6px
}


.img-preview {
    overflow: hidden;
    border: 1px dashed #c51010;
}

.ant-upload-select {
    width: 100%
}